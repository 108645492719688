/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'expense',
    loadChildren: () => import('./pages/expense/expense.module').then(m => m.ExpensePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'expense-history',
    loadChildren: () => import('./pages/expense-history/expense-history.module').then(m => m.ExpenseHistoryPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'attendance',
    loadChildren: () => import('./pages/attendance/attendance.module').then(m => m.AttendancePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'attendance-detail',
    loadChildren: () => import('./modals/attendance-detail/attendance-detail.module').then(m => m.AttendanceDetailModalModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'balance-transfer',
    loadChildren: () => import('./pages/balance-transfer/balance-transfer.module').then(m => m.BalanceTransferPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'balance-transfer-modal',
    loadChildren: () => import('./modals/balance-transfer-modal/balance-transfer-modal.module').then(m => m.BalanceTransferModalPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'balance-history',
    // eslint-disable-next-line max-len
    loadChildren: () => import('./pages/balance-transfer-history/balance-transfer-history.module').then(m => m.BalanceTransferHistoryPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'expense-detail-modal',
    loadChildren: () => import('./modals/expense-detail-modal/expense-detail-modal.module').then(m => m.ExpenseDetailModalPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'inventory',
    loadChildren: () => import('./pages/inventory/inventory.module').then(m => m.InventoryPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'inventory/material-movement',
    loadChildren: () => import('./pages/material-movement/material-movement.module').then(m => m.MaterialMovementPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'inventory/stock-summary',
    loadChildren: () => import('./pages/stock-summary/stock-summary.module').then(m => m.StockSummaryPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'inventory/movement-history',
    loadChildren: () => import('./pages/movement-history/movement-history.module').then(m => m.MovementHistoryPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'material-stock-detail-modal',
    loadChildren: () => import('./modals/material-stock-detail-modal/material-stock-detail-modal.module').then(m => m.MaterialStockDetailModalPageModule),
    canActivate: [AuthGuardService]
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
