/* eslint-disable @angular-eslint/contextual-lifecycle */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectorRef, Component, Injectable, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { BalanceTransferModalPage } from 'src/app/modals/balance-transfer-modal/balance-transfer-modal.page';
import { BalanceService } from 'src/app/services/balance.service';
import { UserService } from 'src/app/services/user.service';


@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() title = '';
  @Input() username;
  user_info;
  notifications: any;
  page = 'dashboard';
  show = false;
  constructor(
    public balance: BalanceService,
    public modalController: ModalController,
    public router: Router,
    private navCtrl: NavController,
    public user: UserService,
    public cd: ChangeDetectorRef
  ) {

    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // console.log('this is what your looking for ', event.url);
        this.page = event.url.split('/')[1];
        if (event.url == '/' || event.url == '/dashboard') {
          this.page = 'dashboard';
        } if (event.url == '/balance-history') {
          this.page = 'balance-transfer';
        }
        this.user.storage.set('currentPageInfo', { title: this.title, page: this.page });
        this.loadData();
      }
    });
  }

  ngOnInit(): void {

  }

  public loadData() {
    this.user.setUserInfo();
    this.user_info = this.user.getUserInfo();
    const pageInfo = this.user.storage.get('currentPageInfo');
    this.cd.detectChanges();
    if (pageInfo) {
      this.page = pageInfo.page;
      this.title = pageInfo.title;
    }
    this.user.getNotification().subscribe((res: any) => {
      this.notifications = res;
      this.cd.detectChanges();
    });

  }

  async presetPopup(item) {
    this.show = false;
    const modal = await this.modalController.create({
      component: BalanceTransferModalPage,
      cssClass: 'balance-transfer-modal-class',
      componentProps: {
        msg: 'Accept the Amount from ' + item.sender_name
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data.dismissedWith == 'YES') {
      this.acceptAmount(item, true, false);
    }
  }

  goBack() {
    this.navCtrl.back();
  }
  goToTitlePage() {
    // console.log(this.page);
    this.navCtrl.navigateRoot(this.page);
  }
  acceptAmount(item, approved, transaction_status) {
    const postFields = {
      nid: [
        {
          value: parseInt(item.nid, 10)
        }
      ],
      type: [
        {
          target_id: 'balance_transfer'
        }
      ],
      field_is_transfer_approved: [
        {
          value: approved
        }
      ],
      field_transaction_status: [
        {
          value: transaction_status
        }
      ],
    };

    this.balance.update_transfer_request(postFields).subscribe((res: any) => {
      if (res) {
        this.balance.presentToast('Refresh the page to see change');
        this.user.getNotification().subscribe((res: any) => {
          this.notifications = res;
          this.user.initiate();
          window.dispatchEvent(new Event('storageUpdate', { bubbles: true }));
          this.router.navigateByUrl('/dashboard');

        });
      }
      else {
        this.balance.presentToast(res.message);
      }
    });

  }
}
