import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController, AlertController, NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { API_URL, CREATE_NODE_URL } from './constants.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {

  public USERS_LIST_URL = API_URL + "/app/json/list/user";
  BALANCE_HISTORY_LIST_URL = API_URL + "/app/json/user/balance/transfer/history";
  constructor(
    public userService: UserService,
    public toastController: ToastController,
    private httpClient: HttpClient,
    public alertController: AlertController,
    public navCtrl: NavController
  ) {
    this.userService.initiate();
  }

  get_user_list(): Observable<any[]> {
    if (!this.userService.btoa) {
      this.userService.initiate();
    }
    var headers_object = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Basic " + this.userService.btoa);

    const httpOptions = {
      headers: headers_object
    };
    return this.httpClient.get<any>(this.USERS_LIST_URL, httpOptions);
  }

  submit_transfer_request(postData): Observable<any[]> {
    if (!this.userService.btoa) {
      this.userService.initiate();
    }
    var headers_object = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Basic " + this.userService.btoa);

    const httpOptions = {
      headers: headers_object
    };
    return this.httpClient.post<any>(CREATE_NODE_URL, postData, httpOptions);
  }


  update_transfer_request(postData): Observable<any[]> {
    if (!this.userService.btoa) {
      this.userService.initiate();
    }
    var headers_object = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Basic " + this.userService.btoa);

    const httpOptions = {
      headers: headers_object
    };
    return this.httpClient.patch<any>(API_URL + "/node/" + postData.nid[0].value + "?_format=json", postData, httpOptions);
  }


  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }


  getBalanceHistory(page = 0): Observable<any[]> {
    if (!this.userService.btoa || this.userService.btoa == 'undefined') {
      this.userService.initiate();
    }
    var headers_object = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set("Authorization", "Basic " + this.userService.btoa);

    const httpOptions = {
      headers: headers_object
    };
    return this.httpClient.get<any>(this.BALANCE_HISTORY_LIST_URL + '?uid=' + this.userService.auth_info.current_user.uid + '&empid=' + this.userService.auth_info.current_user.uid + '&page=' + page, httpOptions);
  }
}
