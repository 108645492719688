import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, NavController, Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL } from './constants.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isLoggedIn = false;
  SIGNIN_USER_URL = API_URL + "/user/login?_format=json";
  constructor(
    private router: Router,
    public storage: StorageService,
    public toastController: ToastController,
    private http: HttpClient,
    public navCtrl: NavController,
    public menu: MenuController
  ) {

  }

  setLoggedIn(_value) {
    this.storage.set('isLoggedIn', _value);
  }
  isAuthenticated(): boolean {
    return this.storage.get('isLoggedIn');
  }

  login(body): Observable<any> {
    return this.http.post<any>(this.SIGNIN_USER_URL, body);
  }

  logout() {
    this.storage.remove('USER_INFO');
    this.storage.remove('AUTH_INFO');
    this.storage.remove('btoa');
    this.storage.removeAll();
    this.setLoggedIn(false);
    this.menu.enable(false);
    this.navCtrl.navigateRoot("/login", { animationDirection: 'forward' });
  }

}