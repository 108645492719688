/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { AuthenticationService } from './services/authentication.service';
import { API_URL } from './services/constants.service';
import { StorageService } from './services/storage.service';
import { UserService } from './services/user.service';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  User: any;
  currentPage: string;
  LOGOUT_USER_URL = API_URL + '/user/logout?_format=json';

  public appPages = [
    { title: 'Home', url: 'dashboard', icon: 'home-menu' },
    { title: 'Task Manager', url: '', icon: 'task-menu' },
    { title: 'Expenses', url: 'expense-history', icon: 'expense-menu' },
    { title: 'Attendance', url: 'attendance', icon: 'attendance-menu' },
    { title: 'Balance Transfer', url: 'balance-history', icon: 'balancetransfer-menu' },
    { title: 'Work in Progress', url: '', icon: 'workinprogress-menu' },
    { title: 'Inventory', url: 'inventory', icon: 'inventory-menu' }

  ];
  constructor(
    public http: HttpClient,
    private _location: Location,
    public alertController: AlertController,
    public storage: StorageService,
    public authService: AuthenticationService,
    router: Router,
    public user: UserService,
    private platform: Platform,
    private screenOrientation: ScreenOrientation) {

    // get current orientation
    console.log(this.screenOrientation.type); // logs the current orientation

    // set to PORTRAIT
    // this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      console.log('Back press handler!');
      if (this._location.isCurrentPathEqualTo('/dashboard')) {

        // Show Exit Alert!
        console.log('Show Exit Alert!');
        this.showExitConfirm();
        processNextHandler();
      } else {

        // Navigate to back page
        console.log('Navigate to back page');
        this._location.back();

      }

    });
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        console.log('this is what your looking for ', event.url);
        this.currentPage = event.url.split('/')[1].replace('-', ' ');
      }
    }
    );

    user.getConstants();

    setInterval(() => {
      this.sessionOut();
    }, 1 * 60 * 60 * 1000);
  }


  showExitConfirm() {
    this.alertController.create({
      header: 'App termination',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          this.logout();
          console.log(navigator);
          (navigator as any).app.exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

  logout() {
    const headers_object = new HttpHeaders()
      .set('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers_object
    };

    // this.http.post<any>(this.LOGOUT_USER_URL + "&token=" + this.user.auth_info?.logout_token, null, httpOptions).subscribe(res => {
    this.authService.logout();
    // });

  }

  sessionOut() {
    this.alertController.create({
      header: 'Session Timeout',
      message: 'Please Login Again?',
      backdropDismiss: false,
      buttons: [{
        text: 'Login',
        handler: () => {
          this.logout();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }
}
