/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { API_URL } from './constants.service';
import { CryptService } from './crypt.service';
import { StorageService } from './storage.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public backbutton;
    public user_info: any;
    public notifications: any;
    public auth_info: any;
    public isLoading = false;
    public USER_PROFILE_URL = API_URL + '/app/json/user/profile';
    public CONSTANTS_URL = API_URL + '/app/json/get/constants.json';
    public USER_NOTIFICATION_URL = API_URL + '/app/user/notification';
    public btoa: string;
    constructor(
        public storage: StorageService,
        public toastController: ToastController,
        private http: HttpClient,
        private crypt: CryptService,
        private authService: AuthenticationService,
        public platform: Platform
    ) {
        if (authService.isAuthenticated()) {
            this.auth_info = storage.get('AUTH_INFO');
            const myDecipher = this.crypt.decipher(this.auth_info.csrf_token);
            this.btoa = myDecipher(storage.get('btoa'));
        }
        this.initiate();
    }

    initiate() {
        console.log('initiate');
        this.auth_info = this.storage.get('AUTH_INFO');
        if (this.authService.isAuthenticated() && !this.btoa) {
            const response = this.storage.get('AUTH_INFO');
            if (response) {
                this.auth_info = response;
                const myDecipher = this.crypt.decipher(response.csrf_token);
                this.btoa = myDecipher(this.storage.get('btoa'));

                this.setUserInfo();
            }
        }
        else {

            this.setUserInfo();
        }
    }

    getProfile(): Observable<any> {
        const headers_object = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Basic ' + this.btoa);

        const httpOptions = {
            headers: headers_object
        };

        return this.http.get<any>(this.USER_PROFILE_URL, httpOptions);
    }

    setUserInfo() {
        if (this.authService.isAuthenticated()) {
            this.getProfile().subscribe((res: any) => {
                this.user_info = res[0];
                this.user_info.user_picture = this.user_info.user_picture ? API_URL + this.user_info.user_picture : 'assets/images/menu_profile.png';
                // this.user_info.field_bank_account_details = Object.fromEntries((this.user_info.field_bank_account_details.split('\r\n').map((i: string) => { return i.split(':') })))
                this.storage.remove('USER_INFO');
                this.storage.set('USER_INFO', this.user_info);
            });
        }
    }

    getNotification(): Observable<any> {
        const headers_object = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Basic ' + this.btoa);

        const httpOptions = {
            headers: headers_object
        };

        return this.http.get<any>(this.USER_NOTIFICATION_URL, httpOptions);
    }

    getUserInfo() {
        return this.storage.get('USER_INFO');
    }

    getConstants() {
        const headers_object = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Basic ' + this.btoa);

        const httpOptions = {
            headers: headers_object
        };

        this.http.get<any>(this.CONSTANTS_URL, httpOptions).subscribe(res => {
            if (res) {
                console.log();
                this.storage.set('expence_booking_allowed_days', res.expence_booking_allowed_days ?? 3);
                this.storage.set('image_compression_level', res.image_compression_level ?? 30);
            }
        }, error => {
            this.storage.set('expence_booking_allowed_days', 3);
            this.storage.set('image_compression_level', 30);
        });
    }
}
